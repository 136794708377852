<script setup lang="ts">
import type { PromoData } from "@/types";

const props = defineProps<{
	data: PromoData;
	locationTid?: string;
}>();

const badgeLabel = parseJSON(props.data.promoOfferPreset?.badgeLabel || "{}");

const handleOpenCashClick = () => {
	dispatchGAEvent({
		event: "click_button",
		offer: props.data?.promoOfferPreset?.bestDeal ? "best_deal" : "most_popular",
		location: "reward_cabinet"
	});
	window?.$store?.gaCash?.deposit?.({
		location: "reward_cabinet"
	});
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.data?.promoOfferPreset?.id}/promoOffer/`);
};

const getBadgeBackground = () => {
	if (badgeLabel?.labelBackground) {
		return `${badgeLabel?.labelBackground}`;
	}
	if (props?.data?.promoOfferPreset?.bestDeal) {
		return "var(--ciamis)";
	}
	return "var(--cartagena)";
};

const getBadgeColor = () => {
	if (badgeLabel?.labelTextColor) {
		return `${badgeLabel?.labelTextColor}`;
	}
	if (props?.data?.promoOfferPreset?.bestDeal) {
		return "var(--cannes)";
	}
	return "var(--cocio)";
};
</script>

<template>
	<div @click="handleOpenCashClick">
		<div class="promo-icon">
			<NuxtImg
				src="nuxt-img/rewards/sale.png"
				width="118"
				height="93"
				loading="lazy"
				format="webp"
				quality="90"
				alt="promo"
			/>
		</div>

		<div class="content">
			<ABadge :background="getBadgeBackground()" variant="info" autosize>
				<AText class="badge" variant="ternopil" :modifiers="['bold', 'uppercase']">
					{{ badgeLabel?.percent }}
					{{ badgeLabel?.text }}
				</AText>
			</ABadge>
			<AText class="prizes text-cannes" variant="tonk" :modifiers="['bold']" as="div">
				<MPrizeFund
					icon="20/coins"
					variant="coins"
					:iconSize="16"
					:data-tid="locationTid ? `reward-coins-${locationTid}` : null"
				>
					{{ numberFormat(Number(data?.promoOfferPreset?.coins)) }}
				</MPrizeFund>
				+
				<MPrizeFund
					icon="20/entries"
					variant="entries"
					:iconSize="16"
					:data-tid="locationTid ? `reward-entries-${locationTid}` : null"
				>
					{{ numberFormat(Number(data?.promoOfferPreset?.entries)) }}
				</MPrizeFund>
			</AText>
		</div>
		<AButton variant="primary" size="s" :data-tid="locationTid ? `reward-btn-${locationTid}` : null">
			<AText variant="topeka" :modifiers="['uppercase']">$ {{ data?.money }}</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.badge {
	color: v-bind(getBadgeColor());
}

.prizes {
	display: flex;
	align-items: center;
	gap: gutter(0.75);
	.prize {
		gap: gutter(0.5);
	}
}

.promo-icon {
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 40px;
	}
}
</style>
